<!--
File: TreatmentMatrix.vue
Description: shows the Treatment Matrix using the vue-pivot-table Component.
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <!--div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <md-input type="search" class="mb-3" clearable style="width: 200px" :placeholder="$t('label.search_records')"
              v-model="searchQuery"></md-input>
          </md-field>
        </div-->
          <div class="md-layout-item md-small-size-100 md-size-20">
            <!--BaseDropdown :label="$t('condition.right_shoulder_type_desc')"
                  :initialValue="sectionGeometry.shoulder_type" :dropdownId='"shoulder_type"'
                  :items="shoulder_pavement_types" :displayField="'description'" :valueField="'description'"
                  @onChange='onShoulderPavementTypeChange' /-->
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addItem"> {{ $t('buttons.add') }} </md-button>
          </div>
        </div>
      </md-card-header>
      <md-card-content>
        <pivot-table id="matrix" :data="treatmentMatrix" :row-fields="rowFields" :col-fields="colFields"
          :reducer="reducer" :is-data-loading="isDataLoading">
          <template slot="value" slot-scope="{ value }" class="md-layout">
            <div :style="{ backgroundColor: value.color || 'white' }" class="flex-container">
              <span class="text-content">{{ value.treatment_key }}</span>
              <div v-if="value.treatment_key" class="button-group">
                <!-- Added check for treatmentMatrixEditable after several requests from Meyyappan to enable/disable editing -->
                   <md-button v-if="treatmentMatrixEditable" class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                  @click.native="editItem(value)">
                  <md-icon>edit</md-icon>
                </md-button>
                <!--md-button v-if="treatmentMatrixEditable" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="deleteItem(value)">
                  <md-icon>delete</md-icon>
                </md-button-->
              </div>
            </div>
          </template>
          <template v-slot:trafficHeader="{ value }">
            <!--div class="text_rotate">{{ getTrafficDescr(value) }}</div-->
            <div class="text_rotate">{{ value }}</div>
          </template>
          <template v-slot:crackingHeader="{ value }">
            Cracking: {{ value }}
          </template>
          <template v-slot:potholesHeader="{ value }">
            Potholes: {{ value }}
          </template>
          <template v-slot:ruttingHeader="{ value }">
            {{ $t('condition.rutting') }}: {{ value }}
          </template>
          <template v-slot:iriHeader="{ value }">
            {{ $t('condition.iri') }}: {{ value }}
          </template>
          <template slot="loading">
            <div class="text-center">
              Loading...
            </div>
          </template>
        </pivot-table>
      </md-card-content>
    </md-card>
    <div>
      <md-dialog :md-active.sync="showTreatmentSelectionDlg" :md-click-outside-to-close="false">
        <md-dialog-title>{{ $t('Change treatment value') }}
          <md-button class='md-simple md-just-icon md-round modal-default-button'
            @click='showTreatmentSelectionDlg = false'>
            <md-icon>clear</md-icon>
          </md-button>
        </md-dialog-title>
        <md-dialog-content>
          <!--TreatmentKeysDropdown-->
          <TreatmentsDropdown v-model="selectedTreatment" :initial_value="selectedTreatment" @input="onTreatmentChange"
            :label="$t('treatment.treatment_key')" />
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-success" @click="saveTreatmentType">{{ $t('buttons.save') }}</md-button>
          <md-button class="md-primary" @click="showTreatmentSelectionDlg = false">{{ $t('buttons.close') }}</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>
<script>
import { PivotTable } from '@click2buy/vue-pivot-table'
import { mapState, mapGetters } from 'vuex'
import TreatmentsDropdown from './TreatmentsDropdown.vue'
import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
import Swal from 'sweetalert2'

export default {
  name: 'treatment-matrix',
  components: {
    PivotTable,
    BaseDropdown,
    TreatmentsDropdown,
  },
  data() {
    return {
      showTreatmentSelectionDlg: false,
      selectedMatrixId: null,
      selectedTreatment: null,
      treatmentDescr: null,
      //defaultShowSettings: true,
      isDataLoading: false,

      reducer: (sum, item) => {
        return {
          item_id: item.treatment_matrix_id,
          treatment: item.fk_treatment_type,
          treatment_key: item.key,
          color: item.color
        }
      },
      rowFields: [{
        getter: item => item.aadt_from == item.aadt_to ? item.aadt_from : item.aadt_from + '-' + item.aadt_to,
        //sort: 'noSort',
        label: 'Traffic',
        headerSlotName: 'trafficHeader',
      }, {
        getter: item => item.cracking_from == item.cracking_to ? item.cracking_from : item.cracking_from + '-' + item.cracking_to,
        label: 'Cracking',
        headerSlotName: 'crackingHeader',
      }, {
        getter: item => item.potholes_from == item.potholes_to ? item.potholes_from : item.potholes_from + '-' + item.potholes_to,
        label: 'Potholes',
        headerSlotName: 'potholesHeader',
        //showFooter: true
      }],
      colFields: [{
        getter: item => item.rutting_from == item.rutting_to ? item.rutting_from : item.rutting_from + '-' + item.rutting_to,
        label: 'Rutting',
        headerSlotName: 'ruttingHeader',
      }, {
        getter: item => item.iri_from == item.iri_to ? item.iri_from : item.iri_from + '-' + item.iri_to,
        label: 'IRI',
        headerSlotName: 'iriHeader'
      }],
      //options: ['RM', 'LR', 'SBSD', 'AC04', 'MR04', 'REC01']
    }
  },

  created() {
    this.reloadData()
  },

  methods: {
    reloadData() {
      this.$store.dispatch('LOAD_TREATMENT_MATRIX').then(() => {
        //console.log(this.treatmentMatrix)
      })
    },

    addItem() {
      console.log('Add item')
    },

    editItem(item) {
      //console.log("Edit:", item.item_id)
      this.selectedMatrixId = item.item_id
      this.selectedTreatment = item.treatment
      this.showTreatmentSelectionDlg = true
    },

    deleteItem(item) {
      const ind = this.treatmentMatrix.findIndex((el) => el.treatment_matrix_id == item.item_id)
      if (ind >= 0) this.treatmentMatrix.splice(ind, 1)
      console.log("deleted (ind, id, key):", ind, item.treatment, item.treatment_key)
    },

    onTreatmentChange(id, descr) {
      //console.log(id, descr)
      //this.treatmentDescr = descr
    },

    async saveTreatmentType() {
      const item = this.treatmentMatrix.find(el => el.treatment_matrix_id == this.selectedMatrixId)
      //const ind = this.treatmentMatrix.findIndex((el) => el.treatment_matrix_id == this.selectedMatrixId)
      let status = ''
      if (!item) {
        status = 'error'
      } else {
        const updItem = {
          rutting_from: item.rutting_from,
          rutting_to: item.rutting_to,
          iri_from: item.iri_from,
          iri_to: item.iri_to,
          aadt_from: item.aadt_from,
          aadt_to: item.aadt_to,
          cracking_from: item.cracking_from,
          cracking_to: item.cracking_to,
          potholes_from: item.potholes_from,
          potholes_to: item.potholes_to,
          fk_treatment_type: this.selectedTreatment
        }

        try {
          await this.$store.dispatch(`UPD_TREATMENT_MATRIX`, { id: this.selectedMatrixId, item: updItem })
          status = 'ok'
        } catch (err) {
          status = err
        }
      }
      const alert = {
        icon: status === 'ok' ? 'success' : 'error',
        text: `${this.$t(`label.was_${status === 'ok' ? '' : 'not_'}saved`, {
          name_ru: 'TreatmentMatrix', 
          name_en: 'TreatmentMatrix' 
        })}`,
        footer: status
      }
      await Swal.fire(alert)
      this.showTreatmentSelectionDlg = false
      this.reloadData()
    },

    getTrafficDescr(value) {
      switch (value) {
        case '1. Low traffic':
          return "Low traffic\n< 1,000 veh/day"
        case '2. Medium traffic':
          return "Medium traffic\n1,000-3,000 veh/day"
        case '3. High traffic':
          return "High traffic\n3,000-7,000 veh/day"
        case '4. Very high traffic':
          return "Very high traffic\n> 7,000 veh/day"
        default:
          return ""
      }
      /*switch (value) {
        case '1. Low traffic':
          return "Low traffic\n< 1,000 veh/day"
        case '2. Medium traffic':
          return "Medium traffic\n1,000-3,000 veh/day"
        case '3. High traffic':
          return "High traffic\n3,000-7,000 veh/day"
        case '4. Very high traffic':
          return "Very high traffic\n> 7,000 veh/day"
        default:
          return ""
      }*/
    },

    onChange(value) {
      console.log(value)

    }
  },

  computed: {
    ...mapState({
      treatmentMatrix: (state) => state.TreatmentMatrix.list,
    }),
    ...mapGetters(['treatmentMatrixEditable']),

    /*
    treatmentMatrix() {
      return this.$store.state.TreatmentMatrix.list
    },*/
  },
}
</script>
<style lang="scss">
$table-cell-padding: .5rem; // default in bs5
$table-cell-padding-sm: .25rem; // default in bs5

.md-card {
  margin: 0px 0;
}

.text_rotate {
  rotate: -90deg;
  white-space: pre;
  width: 50px;
  text-align: center;
  padding: 0px;
}

.value-dropdown {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Отступ между значением и выпадающим списком */
}

#matrix table {
  border: 0.5px solid;
  border-collapse: collapse;
}

#matrix td,
#matrix th {
  border: 0.5px solid;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.flex-container {
  display: flex;
  align-items: center;
  /* Вертикальное выравнивание по центру */
  justify-content: space-between;
  /* Текст слева, кнопки справа */
  padding-left: 5px;
  /* Отступ для текста от левой границы */
  padding-right: 0px;
  /* Отступ для кнопок от правой границы */
}

.text-content {
  flex-grow: 1;
  /* Заставляет текст занять всё оставшееся пространство */
  margin-right: 0px;
  /* Добавляем небольшой отступ между текстом и кнопками */
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0px;
  /* Отступ между кнопками */
}

.md-button {
  min-width: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>